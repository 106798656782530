//
// reboot.scss
// Extended from Bootstrap
//

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	overflow-x: hidden;
	z-index: 0;
	position: relative;
}

header {
	position: relative;
	z-index: $zindex-header;
	background: var(--#{$prefix}body-bg);
}

a {
	transition: $transition-base;
}

%heading > a {
	color: $headings-color;
	&:hover {
		color: var(--#{$prefix}primary);
	}
}

img {
	max-width: 100%;
	height: auto;
}

video {
	background-size: cover;
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}

// Section padding top and bottom
section {
	padding-top: ($spacer * 7.5);
	padding-bottom: ($spacer * 7.5);
}

@include media-breakpoint-down(xl) {
	section {
		padding-top: ($spacer * 5);
		padding-bottom: ($spacer * 4);
	}
}

*:focus-visible {
	outline: none !important;
}
