//
// dark-mode.scss
// Theme Component
// Dark mode related CSS

[data-bs-theme='dark'] {
	// //Dark mode styles
	--bs-light: #292a2d;
	--bs-gray-100: #1f2122;
	--bs-gray-200: #3c3e41;
	--bs-gray-300: #5f5f68;
	--bs-gray-400: #a1a1a8;
	--bs-gray-500: #bfc0c9;
	--bs-gray-600: #c5c6cc;
	--bs-gray-700: #ffffffb3;
	--bs-gray-800: #ffffffd9;
	--bs-gray-900: #fff;
	--bs-body-bg: #161618;
	--bs-body-bg-rgb: #161618;
	--bs-body-color: #c4c5c7;
	--bs-heading-color: var(--bs-gray-900);
	--bs-mode: #191b1d;
	--bs-border-color: rgba(255, 255, 255, 0.07);

	body,
	p,
	ul,
	ol {
		color: var(--#{$prefix}body-color);
	}

	.navbar {
		--bs-navbar-color: var(--#{$prefix}gray-800);
		--bs-navbar-hover-color: var(--#{$prefix}primary);
		--bs-navbar-disabled-color: var(--#{$prefix}gray-400);
		--bs-navbar-active-color: var(--#{$prefix}primary);
	}

	.navbar-light-static .theme-icon-active svg {
		fill: var(--#{$prefix}gray-900);
	}
	.navbar-light-static .theme-icon-active .nav-link.active svg {
		fill: var(--#{$prefix}primary);
	}
	.nav {
		--bs-nav-link-color: var(--#{$prefix}gray-600);
	}
	.dropdown-menu {
		--bs-dropdown-bg: var(--bs-mode);
		--bs-dropdown-link-hover-color: var(--#{$prefix}primary);
		--bs-dropdown-link-active-color: var(--#{$prefix}primary);
		--bs-dropdown-link-disabled-color: var(--#{$prefix}gray-200);
		box-shadow: none;
		border: 1px solid var(--#{$prefix}border-color);
	}

	.btn-link {
		--bs-link-color: var(--#{$prefix}primary);
		--bs-btn-hover-color: var(--#{$prefix}link-hover-color);
		--bs-btn-active-color: var(--#{$prefix}link-hover-color);
	}
	.modal {
		--bs-modal-bg: var(--#{$prefix}body-bg);
		--bs-modal-border-color: var(--#{$prefix}border-color-translucent);
		--bs-modal-footer-border-color: var(--#{$prefix}border-color);
	}
	.table-light {
		--bs-table-bg: var(--#{$prefix}light);
		--bs-table-color: var(--#{$prefix}gray-900);
	}
	.offcanvas,
	.offcanvas-xxl,
	.offcanvas-xl,
	.offcanvas-lg,
	.offcanvas-md,
	.offcanvas-sm {
		--bs-offcanvas-bg: var(--#{$prefix}body-bg);
	}
	.nav-pills {
		--bs-nav-pills-link-active-bg: var(--#{$prefix}primary);
	}
	.card {
		--bs-card-cap-bg: var(--#{$prefix}mode);
		--bs-card-bg: var(--#{$prefix}mode);
	}

	header.header-sticky-on {
		border-bottom: $border-width solid var(--#{$prefix}border-color);
	}

	// Alert
	@each $color, $value in $theme-colors {
		.alert-#{$color} {
			--#{$prefix}alert-bg: rgba(#{to-rgb($value)}, 0.05);
			--#{$prefix}alert-border-color: rgba(#{to-rgb($value)}, 0.25);
			--#{$prefix}alert-color: rgba(#{to-rgb($value)}, 0.8);
		}
	}

	.alert-dark {
		--#{$prefix}alert-color: var(--#{$prefix}gray-700);
		--#{$prefix}alert-border-color: var(--#{$prefix}border-color);
	}

	.alert-light {
		--#{$prefix}alert-bg: var(--#{$prefix}light);
		--#{$prefix}alert-color: var(--#{$prefix}body-color);
		--#{$prefix}alert-link-color: #{$white};
		--#{$prefix}alert-border-color: var(--#{$prefix}gray-200);
	}
	.alert-secondary {
		--#{$prefix}alert-bg: rgba(var(--#{$prefix}secondary-rgb), 0.2);
		--#{$prefix}alert-color: var(--#{$prefix}body-color);
		--#{$prefix}alert-link-color: #{$white};
		--#{$prefix}alert-border-color: var(--#{$prefix}border-color);
	}

	.btn-outline-dark {
		--bs-btn-color: var(--#{$prefix}gray-300);
		--bs-btn-border-color: var(--#{$prefix}gray-300);
	}

	// Input group text border
	.input-group {
		.input-group-text {
			&:first-of-type:not(:last-child) {
				border-right-color: transparent;
			}
			&:last-of-type:not(:first-child) {
				border-left-color: transparent;
			}
		}
	}

	// Floting label bg color
	.input-floating-label.form-floating > .form-control:focus ~ label,
	.input-floating-label.form-floating > .form-control:not(:placeholder-shown) ~ label,
	.input-floating-label.form-floating > .form-control-plaintext ~ label,
	.input-floating-label.form-floating > .form-select ~ label {
		background: var(--#{$prefix}mode);
	}
	.input-floating-label.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
	.input-floating-label.form-floating > .form-control:focus ~ label::after,
	.input-floating-label.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
	.input-floating-label.form-floating > .form-control-plaintext ~ label::after,
	.input-floating-label.form-floating > .form-select ~ label::after {
		background: var(--#{$prefix}mode);
	}

	.form-control:focus {
		border-color: var(--#{$prefix}gray-300);
		box-shadow: 0 7px 15px 3px rgba(42, 44, 46, 0.3);
	}
	.form-control.focus-shadow-none:focus {
		box-shadow: none;
	}
	.form-select:focus {
		border-color: var(--#{$prefix}gray-300);
		box-shadow: 0 7px 15px 3px rgba(42, 44, 46, 0.3);
	}
	.form-select.focus-shadow-none:focus {
		box-shadow: none;
	}
	.bg-light {
		background-color: var(--#{$prefix}light) !important;
	}
	.fill-light {
		fill: var(--#{$prefix}light) !important;
	}
	.border-light {
		border-color: var(--#{$prefix}light) !important;
	}
	.bg-body {
		background-color: var(--#{$prefix}body-bg) !important;
	}
	.shadow,
	.shadow-lg,
	.shadow-sm {
		box-shadow: none !important;
	}
	.navbar-nav .dropdown .dropdown-submenu:hover > .dropdown-menu {
		box-shadow: none;
	}
	.btn-close {
		filter: invert(1) grayscale(100%) brightness(300%);
	}
	.form-check-input:checked {
		background-color: var(--#{$prefix}primary);
		border-color: var(--#{$prefix}primary);
	}

	.text-primary-hover {
		a {
			color: var(--#{$prefix}gray-600);
		}
	}

	.text-primary-hover {
		li {
			color: var(--#{$prefix}gray-600) !important;
		}
	}

	// Mobile color
	.iphone-x {
		background-color: var(--#{$prefix}gray-200);
		box-shadow:
			0px 0px 0px 11px var(--#{$prefix}gray-200),
			0px 0px 0px 13px var(--#{$prefix}gray-200),
			0px 0px 0px 10px shade-color($dark, 20%),
			0px 5px 7px 13px rgba($gray-200, 0.04);
		// frontal camera/speaker frame
		&:before {
			background-color: var(--#{$prefix}gray-200);
		}
		// speaker
		i {
			background-color: $black;
			box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
		}
		// camera
		b {
			background-color: $black;
			box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
		}
	}

	// Accordion border
	.accordion.accordion-border {
		.accordion-button {
			&[aria-expanded='true'] {
				color: var(--#{$prefix}gray-100);
			}
			&[aria-expanded='true']:before {
				background: var(--#{$prefix}gray-100) !important;
			}
			&[aria-expanded='true']:after {
				background: var(--#{$prefix}gray-100) !important;
			}
		}
	}
}

// Dark mode item hide and show
.dark-mode-item {
	display: none !important;
}

[data-bs-theme='dark'] {
	.dark-mode-item {
		display: inline-block !important;
	}
	.light-mode-item {
		display: none !important;
	}
}
