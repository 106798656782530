//
// card.scss
// Extended from Bootstrap
//

//
// Additional styles for theme
//

// Quick fix for border radius issue in safari
.card.overflow-hidden,
.tiny-slider.overflow-hidden {
	will-change: transform;
}

.card-header-title {
	margin-bottom: 0;
}

// Card image overlay z index
.card {
	.card-img-overlay {
		z-index: 2;
	}
}

// Card hover element show
.card.card-element-hover {
	.hover-element {
		visibility: hidden;
		opacity: 0;
		transition: $transition-base;
		z-index: 9;
	}
	.card-text,
	img {
		transition: $transition-base;
	}
	&:hover {
		.card-text {
			margin-bottom: 10px;
		}
		.hover-element {
			visibility: visible;
			opacity: 1;
			transition: $transition-base;
		}
	}
}

// Card-image scale
.card.card-img-scale {
	.card-img-scale-wrapper {
		transform: translateZ(0);
		position: relative;
		overflow: hidden;
	}
	.img-scale {
		transition: all 0.5s ease;
		transform: scale(1.001);
	}
}
.card.card-img-scale:hover .img-scale {
	transform: scale(1.03);
}

// Card-image hover blur
.card.card-img-blur:hover .img-blur {
	filter: blur(5px);
}

//Card metro
.card-metro-hover {
	overflow: hidden;
	&:after {
		content: '';
		opacity: 0;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: $transition-base;
		background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1));
	}
	&:hover:after {
		opacity: 1;
	}
}

// Card hover shadow
.card.card-hover-shadow {
	transition: $transition-base;
	&:hover {
		transform: translateY(-4px);
		box-shadow: 0px 10px 30px rgba(29, 58, 83, 0.15);
	}
}
