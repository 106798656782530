//
// badge.scss
// Extended from Bootstrap
//

//
// Additional style for theme
//
a.badge {
	&:hover {
		color: $white;
	}
}
