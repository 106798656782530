.btn {
	white-space: nowrap;
}

body {
	overscroll-behavior-y: none;
	@include bg-font;
}

.list-inline-item {
	white-space: nowrap;
}

.bg-red {
	background-color: #d34f2d !important;
	color: white;
	h1 {
		color: white;
	}
}

/* Default (for mobile screens) */
.truncate-multi-line {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 4rem); /* Adjusts width leaving space for the date */
}

/* For screens larger than 768px (md and above) */
@media (min-width: 768px) {
	.truncate-multi-line {
		-webkit-line-clamp: 1;
	}
}

.fs-normal {
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
}

/* Override Components */

.nav-link {
	color: var(--#{$prefix}gray-900);
	font-weight: 400;
}

/* ADD */

.border-black-hover {
	border: 1px solid transparent;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.border-black-hover:hover {
	border: 1px solid var(--bs-black) !important;
}

// Language specific styles - using HTML lang attribute
:lang(en) {
	@include en-font;
}

:lang(bg) {
	@include bg-font;
}

// Alternative class-based selectors if needed
.en {
	@include en-font;
}

.bg {
	@include bg-font;
}
