//
// floating-labels.scss
// Extended from Bootstrap
//

//
// Additional style for theme
//

.input-floating-label.form-floating > label {
	padding: 0.75rem;
	height: auto;
}
.input-floating-label.form-floating > .form-control:focus ~ label,
.input-floating-label.form-floating > .form-control:not(:placeholder-shown) ~ label,
.input-floating-label.form-floating > .form-control-plaintext ~ label,
.input-floating-label.form-floating > .form-select ~ label {
	transform: scale(0.85) translateY(-1.2rem) translateX(0.3rem) !important;
	background: var(--#{$prefix}body-bg);
	padding: 0.3rem;
}
.input-floating-label.form-floating > .form-control:focus,
.input-floating-label.form-floating > .form-control:not(:placeholder-shown),
.input-floating-label.form-floating > .form-control-plaintext:focus,
.input-floating-label.form-floating > .form-control-plaintext:not(:placeholder-shown) {
	padding-top: 0.7rem;
}

.input-floating-label.form-floating > .form-control,
.input-floating-label.form-floating > .form-control-plaintext,
.input-floating-label.form-floating > .form-select {
	height: calc(3rem + 2px);
	min-height: calc(3rem + 2px);
}
