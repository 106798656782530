//
// nav.scss
// Extended from Bootstrap
//

//
// Additional style for theme
//

// Pills
.nav-pills {
	.nav-link {
		cursor: pointer;
		// margin-bottom: 15px;
		padding: 0.5rem 1rem;
		color: var(--#{$prefix}primary);
		white-space: nowrap;
	}
}

// Nav link padding left 0
.nav.flex-column:not(.nav-pills) .nav-link {
	padding-left: 0;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
}

// Nav responsive
@include media-breakpoint-down(lg) {
	.nav-responsive {
		overflow-x: scroll;
		flex-wrap: nowrap;
	}
}

// Nav divider
.nav.nav-divider {
	a:not(.dropdown-item) {
		color: inherit;
	}
	.nav-item + .nav-item:before {
		content: '\2022';
		color: inherit;
		padding-left: 0.65rem;
		padding-right: 0.75rem;
		opacity: 0.8;
	}
	.nav-item {
		display: inline-block;
		vertical-align: middle;
	}
	.nav-link {
		padding: 0;
		color: inherit;
	}
}

// Nav pills primary
.nav-pills-primary {
	.nav-item {
		padding: 0 !important;
		margin-right: 2px;
		margin-left: 2px;
		border-bottom: 0 !important;
	}
	.nav-link {
		padding: 0.5rem 0.8rem !important;
		color: var(--#{$prefix}gray-800);
		font-weight: 500;
		border-radius: $border-radius;

		&.active,
		&:hover {
			color: var(--#{$prefix}white);
			background-color: var(--#{$prefix}primary);
		}
	}
}
.nav-pills-primary.flex-column {
	.nav-item {
		margin-bottom: 3px;
		margin-right: 0;
	}
}

// Nav pill primary soft
.nav-pills-primary-soft .nav-link {
	background-color: transparent;
	color: var(--#{$prefix}body-color);
	border: none;
	font-weight: 500;
	&.active,
	&:hover {
		color: var(--#{$prefix}primary);
		background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
	}
}

// Nav pill dark
.nav-pills-dark .nav-link {
	background-color: transparent;
	color: var(--#{$prefix}gray-900);
	border: none;
	font-weight: 600;
}
.nav-pills-dark .nav-link.active,
.nav-pills-dark .show > .nav-link {
	color: var(--#{$prefix}body-bg);
	background-color: var(--#{$prefix}gray-900);
}

// Nav pill light
.nav-pills-light .nav-link {
	background-color: transparent;
	color: var(--#{$prefix}body-color);
	border: 1px solid transparent;
}
.nav-pills-light .nav-link.active,
.nav-pills-light .nav-link:hover,
.nav-pills-light .show > .nav-link {
	color: var(--#{$prefix}gray-900);
	background-color: var(--#{$prefix}light);
	border: 1px solid var(--#{$prefix}border-color);
}

// Nav pill feature
.nav-pills-border-start .nav-link {
	background-color: transparent;
	color: var(--#{$prefix}body-color);
	border: 3px solid transparent;
	white-space: normal;
}
.nav-pills-border-start .nav-link.active,
.nav-pills-border-start .nav-link:hover,
.nav-pills-border-start .show > .nav-link {
	color: var(--#{$prefix}gray-900);
	background-color: var(--#{$prefix}light);
	border-left: 3px solid var(--#{$prefix}primary);
}

// Nav Circle
.nav-tabs-radio {
	.nav-link {
		label {
			width: 1.25rem;
			height: 1.25rem;
			border-radius: 50%;

			span {
				width: 0.75rem;
				height: 0.75rem;
				border-radius: 50%;
				box-shadow: $box-shadow;
			}
		}
		&:hover {
			border-radius: 50%;
			border-color: transparent;
		}
		&.active {
			background-color: transparent;
			border: 1px solid transparent;
			border-radius: 50%;
		}

		.btn-check:checked + .btn {
			border-color: var(--#{$prefix}gray-900) !important;
		}
	}
}

// Account pages sidebar tab style
.nav-pills-primary-border-start {
	.nav-item {
		padding: 0 !important;
		margin-right: 2px;
		margin-left: 2px;
		border-bottom: 0 !important;
	}
	.nav-link {
		padding: 0.5rem 0.8rem !important;
		color: var(--#{$prefix}gray-800);
		font-weight: 500;
		border-radius: $border-radius;
		border-left: 2px solid transparent;

		&.active,
		&:hover {
			color: var(--#{$prefix}primary);
			background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
			border-left: 2px solid var(--#{$prefix}primary);
		}
	}
}
.nav-pills-primary-border-start.flex-column {
	.nav-item {
		margin-bottom: 4px;
		margin-right: 0;
	}
}

// Nav pill testimonials
.nav-pills-testimonial .nav-link {
	background-color: transparent;
	color: var(--#{$prefix}body-color);
	border: 1px solid transparent;
	opacity: 50%;
}
.nav-pills-testimonial .nav-link:hover {
	opacity: 100%;
}
.nav-pills-testimonial .nav-link.active,
.nav-pills-testimonial .show > .nav-link {
	color: var(--#{$prefix}gray-900);
	background-color: var(--#{$prefix}light);
	border: 1px solid var(--#{$prefix}border-color);
	opacity: 100%;
}
